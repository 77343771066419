import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Navigation from '../components/navigation';
import 'prismjs/themes/prism-okaidia.css';

export default ({ children }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );
  const heroStyle = {
    padding: 0,
  };
  return (
    <div className="site-wrapper">
      <header className="site-header">
        <div className="site-title">
          {/* <Link to="/"> */}
          <a href="https://www.gicly.com">
            <div style={heroStyle}>
              <img
                src={
                  'https://www.gicly.com/giclee-cdn/giclee-images/webportal/gicly_logo_red.png'
                }
                style={{ width: 70 }}
              />
            </div>
          </a>
          {/* </Link> */}
        </div>
      </header>
      {children}
      <footer className="site-footer">
        <p>
          &copy; {new Date().getFullYear()} Gicly Software Private Limited
          &bull; Made in India, with{' '}
          <span role="img" aria-label="love">
            ❤️
          </span>
        </p>
      </footer>
    </div>
  );
};
